// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* Headings */\n\n.V0GtXaDYMTAHiUJ1 {\n  font-size: 3rem;\n}\n\n.HyvU23AltM34Vv92 {\n  font-size: 2.2rem;\n}\n\n.oeTtR85AVG4u5QvB {\n  font-size: 1.8rem;\n}\n\n.QmAtERQ2Pzky6OT5 {\n  font-size: 1.4rem;\n}\n\n.V0GtXaDYMTAHiUJ1,\n.HyvU23AltM34Vv92,\n.oeTtR85AVG4u5QvB,\n.QmAtERQ2Pzky6OT5 {\n  color: #004165;\n  font-weight: 600;\n  margin-bottom: 0.55em;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ThemeInjector/heading/heading.module.css"],"names":[],"mappings":"AAAA,aAAa;;AAEb;EACE,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;;;;EAIE,cAAc;EACd,gBAAgB;EAChB,qBAAqB;AACvB","sourcesContent":["/* Headings */\n\n.heading-1 {\n  font-size: 3rem;\n}\n\n.heading-2 {\n  font-size: 2.2rem;\n}\n\n.heading-3 {\n  font-size: 1.8rem;\n}\n\n.heading-4 {\n  font-size: 1.4rem;\n}\n\n.heading-1,\n.heading-2,\n.heading-3,\n.heading-4 {\n  color: #004165;\n  font-weight: 600;\n  margin-bottom: 0.55em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading-1": "V0GtXaDYMTAHiUJ1",
	"heading-2": "HyvU23AltM34Vv92",
	"heading-3": "oeTtR85AVG4u5QvB",
	"heading-4": "QmAtERQ2Pzky6OT5"
};
export default ___CSS_LOADER_EXPORT___;
