import PropTypes from "prop-types";
import React from "react";

import ButtonStyles from "./button/button.module.css";
import FormStyles from "./form/form.module.css";
import HeadingStyles from "./heading/heading.module.css";
import DialogStyles from "./dialog/dialog.module.css";
import LayoutStyles from "./layout/layout.module.css";
import NotificationStyles from "./notification/notification.module.css";
import PanelStyles from "./panel/panel.module.css";

interface Styles {
  [key: string]: any;
}

const stylesMap: Styles = {
  Button: ButtonStyles,
  ButtonBar: ButtonStyles,
  InputField: FormStyles,
  Input: FormStyles,
  Field: FormStyles,
  FieldLabel: FormStyles,
  Label: FormStyles,
  PasswordInputField: FormStyles,
  Select: FormStyles,
  SelectField: FormStyles,
  Heading: HeadingStyles,
  Dialog: DialogStyles,
  Row: LayoutStyles,
  Column: LayoutStyles,
  InlineNotification: NotificationStyles,
  ContentPanel: PanelStyles,
  ElementWrapper: FormStyles,
  Error: FormStyles,
  ErrorWrapper: FormStyles,
  PasswordInput: FormStyles,
};

function ThemeInjector(WrappedComponent: any) {
  function ThemeInjector(props: any) {
    const { setRef, ...otherProps } = props;

    const componentName = WrappedComponent.displayName || WrappedComponent.name;
    const styles = stylesMap[componentName] || FormStyles;

    const onSetRef = (ref: any) => {
      setRef && setRef(ref);
    };

    return <WrappedComponent {...otherProps} ref={onSetRef} styles={styles} />;
  }

  ThemeInjector.displayName = WrappedComponent.displayName;
  ThemeInjector.propTypes = WrappedComponent.propTypes;

  return ThemeInjector;
}

export default ThemeInjector;
